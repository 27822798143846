//import to  CustomerStories.tsx


const Cs = {
    term1: {
        stars: 5,
        comments: "Domax's financing solutions have been a game-changer for our business.",
        customerName: 'John Doe',
        customerPosition: 'CEO'
      },

      term2: {
        stars: 5,
        comments: "Thanks to Domax, we were able to expand our operations and increase our revenue.",
        customerName: 'Jane Smith',
        customerPosition: 'CFO',
      },

      term3: {
        stars: 5,
        comments: "Domax's financing options revolutionized our growth strategy. Grateful for their support.",
        customerName: 'Sarah Johnson',
        customerPosition: 'CEO',
      },

    term4: {
        stars: 5,
        comments: "Domax's financial solutions provided the boost we needed. Exceptional service!",
        customerName: 'Emily White',
        customerPosition: 'Founder',
      },

    term5: {
        stars: 5,
        comments: "Domax's tailored financing solutions fueled our progress. Highly recommend their expertise.",
        customerName: 'Alex Reynolds',
        customerPosition: 'CEO',
      },

      term6: {
        stars: 5,
        comments: "Domax played a pivotal role in our success story. Their financing is a game-changer.",
        customerName: 'David Turner',
        customerPosition: 'CFO',
      },
    };
  
  export default Cs;
  