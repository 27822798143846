import QuickApprovalIcon from '../images/documentation 1.svg';
import CompetitiveRatesIcon from '../images/ranking 1.svg';
import CustomizableSolutionsIcon from '../images/negotiation 1.svg';

//import to Features.tsx


const ft = {
    term1: {
        imageUrl: QuickApprovalIcon, 
        title: 'Quick Approval',
        description:
          'Get approved quickly with us and enjoy flexible terms that suit your business needs.',
      },

      term2: {
        imageUrl: CompetitiveRatesIcon,
        title: 'Competitive Rates',
        description: 'We offer competitive rates to get you the best value for your business.',
      },

      term3: {
        imageUrl: CustomizableSolutionsIcon,
    title: 'Customizable Solutions',
    description: 'We offer customizable solutions to meet your specific financing needs.',
      }
    };
  
  export default ft;
  