import classes from './StillHaveQuestion.module.css';
import arrow1 from '../../images/Arrows 1.png';
import arrow2 from '../../images/Arrows 2.png';
import { Link } from 'react-router-dom';


const StillHaveQuestion = () => {
  return (
    <div className={classes.container}>
      <img src={arrow1} alt="Left Arrow" className={classes.leftImage} />
      <div className={classes.content}>
        <h2 className={classes.title}>Still have questions?</h2>
        <p className={classes.description}>
          Do not hesitate to reach out to our team for further assistance
        </p>
        <button className={classes.button}>
        <Link to="/contactus" style={{ textDecoration: 'none' }} className={classes.button}>
          Contact Us
        </Link>
        </button>

      </div>
      <img src={arrow2} alt="Right Arrow" className={classes.rightImage} />
    </div>
  );
};

export default StillHaveQuestion;
