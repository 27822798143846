import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import WhatsappLogo from "../components/Static/WhatsappLogo";
import { ContactUs } from "../components/ContactPage/contactus";
import { HeaderWhite } from "../components/Static/HeaderWhite";
import { Footer } from "../components/Static/Footer";

function ContactUsPage() {
  return (
    <MantineProvider>
      <div className="App">
        <HeaderWhite />
        <ContactUs />
        <Footer />
        <WhatsappLogo />
      </div>
    </MantineProvider>
  );
}

export default ContactUsPage;
