import { Container, Title, Accordion, Text } from "@mantine/core";
import classes from "./Faq.module.css";
import domaxFaq from "../../strings/FaqRealStrings";

export function Faq() {
  const faqEntries = Object.entries(domaxFaq);
  const splitPoint = Math.ceil(faqEntries.length / 2);
  const firstColumn = faqEntries.slice(0, splitPoint);
  const secondColumn = faqEntries.slice(splitPoint);

  return (
    <div className={classes.container}>
      <Container className={classes.wrapper}>
        <Title className={classes.title}>
          Frequently Asked Questions
          <Text className={classes.titledesc}>
            Find answers to common questions about SME financing and Domax's
            services.
          </Text>
        </Title>
      </Container>

      <div className={classes["faq-columns"]}>
        <Accordion className={classes["accordion-container"]}>
          {firstColumn.map(([key, value]) => (
            <Accordion.Item key={key} className={classes.item} value={key}>
              <Accordion.Control className={classes.question}>
                {value.question}
              </Accordion.Control>
              <Accordion.Panel className={classes.answer}>
                {value.answer}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>

        <Accordion className={classes["accordion-container"]}>
          {secondColumn.map(([key, value]) => (
            <Accordion.Item key={key} className={classes.item} value={key}>
              <Accordion.Control className={classes.question}>
                {value.question}
              </Accordion.Control>
              <Accordion.Panel className={classes.answer}>
                {value.answer}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default Faq;
