import { MantineProvider } from "@mantine/core";
import { CustomerStories } from "./CustomerStories";
import { LatestNews } from "./LatestNews";
import { FaqSimple } from "./FaqPage";
import classes from "./BottomPart.module.css";

function BottomPart() {
  return (
    <MantineProvider>
      <div className={classes.container}>
        <div className={classes.background} />
        <div className={classes.BottomPart}>
          <CustomerStories />
          <FaqSimple />
          {/* <LatestNews /> */}
        </div>
      </div>
    </MantineProvider>
  );
}

export default BottomPart;
