// BottomPart.tsx

import { MantineProvider } from '@mantine/core';

import classes from './ServicesBottomPart.module.css';
import { ServicesFeaturesWhy } from './ServicesFeaturesWhy';
import { ServicesFaq } from './ServicesFaqPage';
import { LoanFunction } from './LoanCalc';

function ServicesBottomPart() {
  return (
    <MantineProvider>
          <ServicesFeaturesWhy />
          <LoanFunction />
          <ServicesFaq />
    </MantineProvider>
  );
}

export default ServicesBottomPart;
