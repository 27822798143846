import { useState, useEffect } from "react";
import { Title, SimpleGrid, Text, Grid } from "@mantine/core";
import classes from "./WhoAreWe.module.css";
import WhoAreWe_Arrow from "../../images/WhoAreWe_Arrow.png";

const Slideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const slideshowImages = getSlideshowImages();

  useEffect(() => {
    if (slideshowImages.length === 0) {
      console.error(
        "No slideshow images found. Check the folder and filenames."
      );
      return;
    }

    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % slideshowImages.length
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [slideshowImages.length]);

  if (slideshowImages.length === 0) {
    return (
      <div>No slideshow images found. Check the folder and filenames.</div>
    );
  }

  return (
      <img
        src={slideshowImages[currentImageIndex]}
        alt={`Slide ${currentImageIndex + 1}`}
        className={classes.images}
      />
  );
};

// Function to get the list of slideshow images
const getSlideshowImages = () => {
  return [
    "ss1.png",
    "ss2.png",
    "ss3.png",
    "ss4.png",
    "ss5.png",
    "ss6.png",
    "ss7.png",
    "ss8.png",
    // Add more images as needed
  ].map((imageName) => require(`../../slideshow/${imageName}`));
};

const features = [
  {
    Image: WhoAreWe_Arrow,
    title: "Who are we",
    description:
      "With a cumulative experience of over 20 years in finance, our management team possess the expertise to offer suitable financing package that meets business needs and helping business to achieve their business goals. Our team will evaluate all application carefully to ensure funding extended benefits the applicant’s cash flow.",
  },
  {
    Image: WhoAreWe_Arrow,
    title: "Our Vision",
    description: (
      <ul>
        <li>
          To establish a straightforward, equitable, and transparent online
          marketplace for the financing ecosystem that empowers people and
          businesses to realize their financial aspirations daily.
        </li>
        <li>
          To foster significant and sustainable growth by addressing financing
          ecosystem gaps and utilizing technology to increase entry-level
          business access to financing and investment opportunities.
        </li>
      </ul>
    ),
  },
  {
    Image: WhoAreWe_Arrow,
    title: "Our Mission",
    description:
      "To assist SMEs to achieve their full potential and sustain future growth by addressing their cash flow issues, enhancing working capital, and facilitating the expansion of their business operations.",
  },
];

export function FeaturesTitle() {
  const items = features.map((feature) => (
    <div className={classes.feature}>
      <div>
        <Text className={classes.destitle}>
          <img
            src={feature.Image}
           
            className={classes.logo}
          />
          {feature.title}
        </Text>
        <Text className={classes.description}>
          {feature.description}
        </Text>
      </div>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid gutter={80}>
        <Grid.Col span={{ base: 12, md: 5 }} className={classes.slider}>
          <Title className={classes.title} order={2}>
            <Slideshow />
          </Title>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <SimpleGrid cols={{ base: 1, md: 1 }} spacing={30}>
            {items}
          </SimpleGrid>
        </Grid.Col>
      </Grid>
    </div>
  );
}
