import { Container, Title, Accordion, Text } from "@mantine/core";
import classes from "./ServicesFaqPage.module.css";
import domaxFaq from "../../strings/ServicesFaqString";

export function ServicesFaq() {
  const faqEntries = Object.entries(domaxFaq);

  return (
    <div>
      <Container size="sm" className={classes.wrapper}>
        <Title className={classes.title}>
          Frequently Asked Questions
          <Text className={classes.titledesc}>
            Find answers to common questions about SME financing and Domax's
            services.
          </Text>
        </Title>
      </Container>

      <Accordion className={classes.accordion}>
        {faqEntries.map(([key, value]) => (
          <Accordion.Item key={key} className={classes.item} value={key}>
            <Accordion.Control className={classes.question}>
              {value.question}
            </Accordion.Control>
            <Accordion.Panel className={classes.answer}>
              {value.answer}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

export default ServicesFaq;
