import React from 'react';
import { MantineProvider } from '@mantine/core';
import WhatsappLogo from '../components/Static/WhatsappLogo';
import '@mantine/core/styles.css';
import { HeaderTransparent } from '../components/Static/HeaderTransparent'; 
import { ServicesHero } from '../components/ServicesPage/ServicesHero';
import { ServicesFeatures} from '../components/ServicesPage/ServicesFeatures';
import ServicesBottomPart from '../components/ServicesPage/ServicesBottomPart';
import StillHaveQuestion from '../components/ServicesPage/ServicesSHQ';
import { Footer } from '../components/Static/Footer';


function App() {
  return (
    <MantineProvider>

      <ServicesHero/>
      <ServicesFeatures />
      <ServicesBottomPart />
      <StillHaveQuestion />
      <Footer />
      <WhatsappLogo />

    </MantineProvider>
  );
}

export default App;
