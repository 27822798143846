// src/strings/loanTerms.js

const domaxFaq = {
  term1: {
    question: "What are the eligible criteria?",
    answer: (
      <div style={{ whiteSpace: "pre-line" }}>
        Customer age should be 18 - 65 years old{"\n"}
        {"\n"}
        Applicant must be Malaysian or Permanent Resident of Malaysia.{"\n"}
        {"\n"}
      </div>
    ),
  },
  term2: {
    question: "Financing Details",
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },

  term3: {
    question: "Disbursement Details",
    answer:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  term4: {
    question: "How long will it take for the fund to be disburse?",
    answer:
      "The funds are expected to be transferred to the distributor within one (1) to two (2) working days, specifically within twenty-four (24) to forty-eight (48) working hours, following the confirmation of product delivery by the merchants",
  },
  term5: {
    question: "What is the repayment duration to Domax?",
    answer:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },


};

export default domaxFaq;
