//import to FaqPage.tsx


const domaxFaq = {
  term1: {
    question: "How can Domax help?",
    answer:
      "Domax offers a range of financing solutions tailored to the needs of SMEs. Our streamlined application process and competitive rates makes it easier for businesses to access the funding they need.",
  },
  term2: {
    question: "Is my information safe and secure?",
    answer:
      "At Domax, we take the security and privacy of your information seriously. We use industry-standard encryption and follow strict data protection protocols to ensure that your data is safe and confidential throughout the financing process.",
  },
  term3: {
    question: "Can I apply online?",
    answer:
      "Yes, you can apply for financing with Domax online. Our user-friendly application form makes it easy to provide the necessary information and documentation. If you have any questions or need assistance, our team is available to help.",
  },
  term4: {
    question: "What are the eligible criteria?",
    answer: (
      <div style={{ whiteSpace: "pre-line" }}>
        a. Malaysia incorporated/registered company and business (Sole
        proprietorship, Joint venture, Limited liability partnership, Limited
        company) that classified as Small Medium Enterprise (SMEs).{"\n"}
        {"\n"}
        b. Business is established for at least 2 years, 24 months.{"\n"}
        {"\n"}
        c. Minimum 1-year existing repayment record. Guarantors' repayment
        record can be considered.{"\n"}
        {"\n"}
        d. Minimum paid-up capital at RM 100k.{"\n"}
        {"\n"}
        e. No on-going legal cases
      </div>
    ),
  },


};

export default domaxFaq;
