import React from "react";
import classes from "./privacy.module.css";
import { Text, Title, Box, Container } from "@mantine/core";
import { HeaderWhite } from "../Static/HeaderWhite";
import { Footer } from "../Static/Footer";

export default function Privacy() {
  return (
    <div>
      <HeaderWhite />
      <Container className={classes.privacy}>
        <Title className={classes.Title}>PRIVACY NOTICE</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            For the purposes of this Privacy Notice, “Domax Credit” “the
            Company”, “we”, “us”, or “our” refers to Domax Credit (M) Sdn Bhd
            and its related corporations and affiliates and“you”, “your” or
            “yours” denotes you as the investor and/or issuer where as an entity
            shall include your subsidiary(s) or any and/or all of its
            subsidiaries connected to you, your parent company or to any
            subsidiary(s) or, any associated company as definedinCompanies Act
            2016 from time to time or generally if a natural person shall
            meantheindividual, providing Personal Data and/or any other
            information, being the subject matter of this Privacy Notice.
            <br />
            <br />
            This Privacy Notice outlines the manner of which the Company
            collects, uses, maintainsand discloses your personal data in respect
            of the Services rendered and transactionsentered into through the
            Website and the manner of which the Company therebysafeguards your
            personal data so collected.
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>1. Interpretation</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            “Personal Data” for the purpose of this Privacy Notice denotes the
            definition of personal data as stipulated under the Personal Data
            Protection Act 2010 (hereinafter referredtoas “the PDPA”) and shall
            mean and/or may include but is not limited to the following:-
            <ol type="i">
              <br />
              <li>your name;</li>
              <br />
              <li>date of birth, passport and/or identity card number;</li>
              <br />
              <li>
                home address, telephone number, facsimile number and/or email
                address;
              </li>
              <br />
              <li>photographs or pictures;</li>
              <br />
              <li>gender, marital and/or family status</li>
              <br />
              <li>race, nationality or national, and/or ethnic origin;</li>
              <br />
              <li>
                education, employment or occupation, or educational, employment
                or occupational history, year of service, working experience,
                employers’ details;
              </li>
              <br />
              <li>
                source of income or financial circumstances, financial
                positions, activities or historyincluding but is not limited to
                your payment history, account activities, account balances, loan
                and/or deposit balances etc.;
              </li>
              <br />
              <li>
                litigation records and criminal history including regulatory
                offence(s);
              </li>
              <br />
              <li>details and background of family members;</li>
              <br />
              <li>details of guarantors and legal representatives;</li>
              <br />
              <li>details of bank accounts and signature specimens;</li>
              <br />
              <li>details of your transactions with the Company; and</li>
              <br />
              <li>
                any such other necessary information of you that are, have been
                and may becollected, compiled and maintained from time to time
                with and by the Companythrough amongst others the following
                manner:-
                <br />
                <ol type="a">
                  <li>your access to the Website;</li>
                  <br />
                  <li>
                    your application form and/or any other forms as provided by
                    you;
                  </li>
                  <br />
                  <li>
                    transactions between the Company and you, its
                    serviceproviders, business partners, affiliates and/or third
                    parties;
                  </li>
                  <br />
                  <li>
                    Credit Reporting Agencies and Credit Bureau Reports, e.g.
                    CTOS (CTOSDataSystems Sdn Bhd), RAMCI (RAM Credit
                    Information Sdn. Bhd.) etc;
                  </li>
                  <br />
                  <li>
                    Governmental Agencies, Regulatory and/or Statutory
                    Authorities;
                  </li>
                  <br />
                  <li>customer surveys; and</li>
                  <br />
                  <li>publicly available sources.</li>
                </ol>
              </li>
              <br />
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            2. Collection of Personal Data
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            The Company collects, uses and/or processes your Personal Data,
            without beingexhaustive, for the following purposes (Permitted
            Purposes) :-
            <ol type="a">
              <br />
              <li>process application(s) submitted by you;</li>
              <br />
              <li>
                communicate with you including responding to your enquiries
                and/or complaints andresolving disputes;
              </li>
              <br />
              <li>
                to notify you about important changes/developments in relation
                to our product andservices;
              </li>
              <br />
              <li>
                to assess and/or verify your credit / financial worthiness;
              </li>
              <br />
              <li>
                for the purposes of strategic alliances, cross selling,
                marketing, and promotionswithin the Company’s course of business
                whether within its related corporations, affiliates, agents
                and/or third parties;
              </li>
              <br />
              <li>
                to detect crime or fraud, and to utilise and disclose the same
                for investigation, prevention, prosecution and compliance with
                sanctions, including knowyour customer (KYC) and regular
                politically exposed persons (PEP) screening purposes;
              </li>
              <br />
              <li>
                for the purpose of complying with regulatory requirements and
                provide assistancetolaw enforcement agencies which may include
                disclosure, notification and recordretention requirements;
              </li>
              <br />
              <li>
                to administer and/or manage products and services rendered by
                the Company and/or any agreement you may have with the Company;{" "}
              </li>
              <br />
              <li>
                to improve and develop the products and services offered and
                rendered by theCompany to you and to ensure better quality
                assurance to you;{" "}
              </li>
              <br />
              <li>
                to utilise and disclose the same for market research and
                statistical analysis andsurveys with the aim of improving our
                products and services and product development;
              </li>
              <br />
              <li>
                to maintain your credit history for present and future reference
                (if any);
              </li>
              <br />
              <li>
                to protect the Company’s interests and other ancillary or
                related purposes;{" "}
              </li>
              <br />
              <li>
                for internal functions such as research and product development,
                evaluatingtheeffectiveness of marketing and promotion campaigns,
                market research, statistical analysis and modelling, reporting,
                audit and risk management and fraud prevention;
              </li>
              <br />
              <li>
                to compare information/data for accuracy of the Company’s
                record, and to verify thesame with third parties;
              </li>
              <br />
              <li>
                to utilise the same for research, design and improvement of our
                products andservices, operational and business processes;
              </li>
              <br />
              <li>
                to support the Company’s business, financial and risk
                monitoring, planning anddecision making;
              </li>
              <br />
              <li>to handle complaints and queries;</li>
              <br />
              <li>
                to transfer or assign our rights and duties under any governing
                terms and conditionsbetween us and yourself;
              </li>
              <br />
              <li>to utilise and disclose the same for security reasons;</li>
              <br />
              <li>
                any purposes in connection with the transactions and/or
                agreements entered intoor to be entered into by you and/or your
                principal/employer with the Company; and
              </li>
              <br />
              <li>other purposes which the Company may reasonably deem fit.</li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            3. Disclosure of Personal Data
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={3}>
              <li>
                1. Your Personal Data so collected, used, processed, managed,
                and/or held by the Company shall be kept confidential at all
                times. For purposes as described herein, the Company may
                disclose, share with and/or transfer your Personal Data to:
                <br />
                <ol type="a">
                  <li>
                    The Company’s agents, holding companies, subsidiaries,
                    affiliates andassociates;
                  </li>
                  <br />
                  <li>
                    Any relevant agents, contractors, third party service
                    providers, professional advisors or brokers, on a need to
                    know basis for the purposes of providingtheir
                    services/advices to the Company;
                  </li>
                  <br />
                  <li>
                    Any credit bureau, credit reporting agencies, credit
                    reference agencies, rating agencies, insurers, insurance
                    brokers, debt-collection agenciesand/or other parties that
                    assist with debt recovery functions for thetransactions
                    entered into under the Website
                  </li>
                  <br />
                  <li>
                    Any financial service providers in relation to the products
                    and services that you have with the Company;
                  </li>
                  <br />
                  <li>
                    Any actual or potential participants or assignee or
                    transferee of theCompany’s rights and/or obligations under
                    any transaction betweentheCompany and you;
                  </li>
                  <br />
                  <li>
                    Any guarantor or security provider for the products and/or
                    services grantedby the Company to you;
                  </li>
                  <br />
                  <li>
                    Any authorities or regulators, including foreign regulators
                    for theperformance of their functions, or any party as
                    required by any lawor anygovernment, quasi-government,
                    administrative, court or tribunal;
                  </li>
                  <br />
                  <li>
                    strategic/business partners with whom we have a relationship
                    withfor specific products and services;
                  </li>
                  <br />
                  <li>
                    any person connected to the enforcement or preservation of
                    any of our rights under your agreements with us; and/or
                  </li>
                  <br />
                  <li>any party authorised and/or consented by you.</li>
                </ol>
              </li>
              <br />
              <li value={3}>
                2. You hereby agree that your Personal Data so collected by the
                Company mayfrom time to time be disclosed by the Company to
                third parties including but not limited to the Company’s Vendor
                for server operation or data processing for thepurposes so
                stipulated herein (Permitted Purpose). While the Company does
                not have full control over the actual management of the Personal
                Data by the thirdparties, the Company endeavours to take all
                reasonable measures to ensurethat a comparable level of
                protection is rendered by the relevant third parties withrespect
                to your Personal Data, in particular, in compliance with the
                PDPAanditspolicies and guidelines thereof.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>4. Security of Personal Data</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={4}>
              <li>
                1. The Company treats the security of your Personal Data with
                utmost importance. The Company keeps, protects, processes and
                keeps secure your Personal Data by maintaining physical,
                electronic and procedural safeguards incompliance with the law,
                and the Company will, as far as practicable, aimtoprevent any
                unauthorized and/or unlawful access, improper processing of,
                andtheaccidental loss, destruction or damage to your Personal
                Data.
              </li>
              <br />
              <li value={4}>
                2. While there may be third party links in the Website which
                leads the user to a thirdparty website, kindly take note that
                the Company has no control and shall not beresponsible for the
                privacy policies and security safeguards with respect toPersonal
                Data so collected through the third party websites. You are
                advisedtoverify the privacy notice in force for the respective
                websites and to contact thewebsite owner / operator in the event
                of any doubt with respect to such privacynotice of the
                respective websites.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>5. Use of Cookies</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            As part of the data collection process, the Company may use Cookies
            and any other similar technologies to collect your information when
            you visit or use the Website includingother channels such as mobile
            application (collectively called “Site”) as part of further
            improving the Website, the Site and your experience in using the
            Website and/or the Site.
            <ol start={5}>
              <li>
                1. What is a cookie?
                <br />
                <br />
                Cookies are small data files that your browser places on your
                computer or device. Cookies help your browser navigate a website
                and the cookies themselvescannot collect any information stored
                on your computer or your files. Whenaserver uses a web browser
                to read cookies they can help a website deliver amore
                user-friendly service. To protect your privacy, your browser
                only gives awebsite access to the cookies it has already sent to
                you.
              </li>
              <br />
              <li value={5}>
                2. Why do we use cookies?
                <br />
                <br />
                Cookies are used by the Company to better understand users’
                behaviour andpreferences through user interactions on the
                Website and/or the Site, in order toimprove user experience in
                using the Website and/or the Site.
              </li>
              <br />
              <li value={5}>
                3. Third Party Cookies
                <br />
                <br />
                The Website and/or the Site may contain links leading to third
                party websites andcookies are customized and managed by these
                third parties websitesrespectively. The Company does not have
                control over such cookies used andshall not be liable for any
                effect and/or impact caused or incidental to the useof such
                cookies by the third party websites. You are encouraged and
                advisedtoreview the usage of cookies by such third party
                websites.{" "}
              </li>
              <br />
              <li value={5}>
                4. How do I reject and delete cookies?
                <br />
                <br />
                If you intend to close and delete permission to use Cookies in
                the Website and/or the Site, you can implement such action by
                following the instruction stipulatedbyeach browser.{" "}
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            6. Right To Access and Amend/Correct Personal Data
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={6}>
              <li>
                1. You may at any point of time request to review and/or access
                the Personal Databy written request to the Company;
              </li>
              <br />
              <li value={6}>
                2. The Company is committed to ensure that the Personal Data
                that we holdisaccurate, complete, not misleading and up-to-date.
                If you would like to review, change or delete the details you
                have supplied us with, you may, request to access, amend or make
                any correction to your Personal Data by submitting a request
                inwriting to the Company;
              </li>
              <br />
              <li value={6}>
                3. Notwithstanding that access is granted to you or any
                correction / updating of your Personal Data is performed, this
                Privacy Notice shall remain valid and effectiveat all times.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>7. Withdrawal of Consent </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={7}>
              <li>
                1. The consent that you have provided to the Company with
                respect to thecollection, usage and disclosure of your Personal
                Data shall remain validuntil such time it is being withdrawn by
                you in writing. Such withdrawal of consent is subject to your
                contractual obligations to disclose certain information under
                theAgreement and/or transactions entered under the Website
                and/or any legal or regulatory requirements.
              </li>
              <br />
              <li value={7}>
                2. The Company reserve the right to take any requisite steps to
                safeguard its rightsif it feels that such removal or cessation
                of consent by you precludes theCompany to provide or continue to
                provide products and services or fulfil theCompany’s obligations
                in a satisfactory manner. This includes the Company’sentitlement
                to refuse to provide or cease providing any facilities, products
                or services to you.
              </li>
              <br />
              <li value={7}>
                3. You shall allow the Company reasonable time for the
                withdrawal and/or removal of such Personal Data upon your
                written request thereof.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            8. Transfer of Personal Data{" "}
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            You are entitled to request to the Company for the transfer of your
            Personal Data to your preferred third party by written request to
            the Company. Kindly take notice that suchrequest to transfer your
            Personal Data can only be performed where and when
            technicallyfeasible and in accordance of the laws and regulations.
            The Company shall not beresponsible for the security of the Personal
            Data or the processing of the same oncereceived by a third party. We
            reserve our rights to refuse the transfer if such transfer would
            interfere with another person’s rights (e.g. where providing the
            Personal Datawehold about you would reveal information about another
            person)
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            9. Changes to Privacy Policy{" "}
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            The Company may at its sole and absolute discretion update, review
            and/or amendthePrivacy Notice at any point of time by posting of the
            updated Privacy Notice ontheWebsite. Your continued usage of the
            Website and the Services rendered thereon shall be deemed as
            acceptance of any updates, revisions or amendments made to the
            PrivacyNotice.
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            10. Consent and Acknowledgement{" "}
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            By your continued access and usage of the Website and subscription
            to the Servicesrendered thereon, you hereby acknowledged that you
            have read and understoodthecontents of this Privacy Notice and
            thereby consented to the collection, usage, processingand disclosure
            of your Personal Data as herein stipulated and in accordance
            withthePDPA and any other relevant laws.
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>11. Contact </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            If you have any questions with regards to our Website Privacy
            Notice, please do not hesitate to contact us with any queries or
            comments by sending us an email to{" "}
            <a href="https://domaxcredit.co/">Domax Credit</a>{" "}
          </Text>
        </Container>
      </Container>
      <br />
      <br />

      <Container className={classes.privacy}>
        <Title className={classes.Title}>NOTIS PRIVASI</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Bagi tujuan Notis Privasi ini, “Domax Credit”, “Syarikat tersebut”,
            “kita” atau “kami” merujuk kepada Domax Credit (M) Sdn Bhd dan
            syarikat bersekutu dan anak syarikat dan“kamu” atau “anda”
            menandakan anda sebagai pelabur dan/atau pengeluar di manasebagai
            suatu entiti yang merangkumi anak syarikat anda dan/atau mana-mana
            dan/atausemua anak syarikatnya yang berkaitan dengan anda, syarikat
            induk anda atau kepadamana-mana anak syarikat atau, mana-mana
            syarikat bersekutu seperti yang ditakrifkandalam Akta Syarikat 2016
            dari semasa ke semasa atau secara amnya jika orangsebenar bermaksud
            individu yang memberikan Data Peribadi dan/atau sebarangmaklumat
            lain, yang menjadi perkara utama Notis Privasi ini.
            <br />
            <br />
            Notis Privasi ini menggariskan cara Syarikat tersebut mengumpul,
            menggunakan, mengekalkan dan mendedahkan data peribadi anda
            berkenaan dengan Perkhidmatanyang diberikan dan transaksi yang
            dibuat melalui Laman Web tersebut dan cara Syarikat tersebut dengan
            itu melindungi data peribadi anda yang dikumpul sedemikian.
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>1. Tafsiran</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            “Data Peribadi” bagi tujuan Notis Privasi ini menandakan maksud data
            peribadi seperti yang ditetapkan di bawah Akta Perlindungan Data
            Peribadi 2010 (selepas ini dirujuksebagai “PDPA”) dan bermaksud
            dan/atau adalah termasuk namun tidak terhad kepadaberikut:-
            <ol type="i">
              <br />
              <li>nama anda;</li>
              <br />
              <li>tarikh lahir, pasport dan/atau nombor kad pengenalan;</li>
              <br />
              <li>
                alamat rumah, nombor telefon, nombor faksimili dan/atau alamat
                e-mel;
              </li>
              <br />
              <li>foto atau gambar;</li>
              <br />
              <li>jantina, status perkahwinan dan/atau kekeluargaan;</li>
              <br />
              <li>
                bangsa, kewarganegaraan dan/atau asal-usul kebangsaan atau
                etnik;
              </li>
              <br />
              <li>
                vii. pendidikan, pekerjaan atau sejarah Pendidikan atau
                pekerjaan, tahunperkhidmatan, pengalaman bekerja, butiran
                majikan;
              </li>
              <br />
              <li>
                sumber pendapatan atau keadaan kewangan, kedudukan kewangan,
                aktiviti atau sejarah termasuk tetapi tidak terhad kepada
                sejarah pembayaran, aktiviti akaun, baki akaun, baki pinjaman
                dan/atau deposit dll.;
              </li>
              <br />
              <li>
                rekod litigasi dan sejarah jenayah termasuk kesalahan peraturan;
              </li>
              <br />
              <li>butiran dan latar belakang ahli keluarga;</li>
              <br />
              <li>butiran penjamin dan wakil sah;</li>
              <br />
              <li>butiran akaun bank dan spesimen tandatangan;</li>
              <br />
              <li>
                butiran transaksi antara anda dengan Syarikat tersebut; dan
              </li>
              <br />
              <li>
                sebarang maklumat lain yang diperlukan tentang anda yang, telah
                dan mungkindikumpul, disusun dan diselenggara dari semasa ke
                semasa dengan dan olehSyarikat tersebut melalui antara lainnya
                dengan cara berikut:-
                <br />
                <ol type="a">
                  <li>yakses anda terhadap Laman Web tersebut;</li>
                  <br />
                  <li>
                    borang permohonan anda dan/atau sebarang borang lain
                    yangdibekalkan oleh anda;
                  </li>
                  <br />
                  <li>
                    urus niaga antara Syarikat tersebut dan anda, pembekal
                    perkhidmatannya, rakan kongsi perniagaannya, syarikat
                    sekutunya dan/atau pihak ketiga;
                  </li>
                  <br />
                  <li>
                    Agensi Pelaporan Kredit dan Laporan Biro Kredit, seperti :
                    CTOS (CTOSDataSystems Sdn Bhd), RAMCI (RAM Credit
                    Information Sdn. Bhd.) dll;
                  </li>
                  <br />
                  <li>
                    Agensi Kerajaan, Pihak Berkuasa Kawal Selia dan/atau
                    Badan-badan Berkanun;
                  </li>
                  <br />
                  <li>kaji selidik pelanggan; dan</li>
                  <br />
                  <li>sumber am atau awam yang sedia ada</li>
                </ol>
              </li>
              <br />
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>2. Pengumpulan Data Peribadi</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Syarikat tersebut mengumpul, menggunakan dan/atau memproses Data
            Peribadi anda, tanpa menjadi menyeluruh, untuk tujuan berikut
            (Tujuan Yang Dibenarkan):-
            <ol type="a">
              <br />
              <li>
                untuk memproseskan permohonan yang dikemukakan oleh anda
                berkenaan denganperkhidmatan yang ditawarkan oleh Syarikat
                tersebut;
              </li>
              <br />
              <li>
                untuk tujuan komunikasi dengan anda termasuk untuk menjawab
                pertanyaandan/atau aduan anda dan untuk penyelesaian pertikaian;
              </li>
              <br />
              <li>
                untuk memaklumkan kepada anda tentang perubahan/perkembangan
                pentingberhubung dengan produk dan perkhidmatan kami;
              </li>
              <br />
              <li>
                untuk menilai dan/atau mengesahkan kelayakan kredit / kewangan
                anda;{" "}
              </li>
              <br />
              <li>
                untuk tujuan pakatan strategik, jualan silang, pemasaran, dan
                promosi dalamperjalanan perniagaan Syarikat tersebut, sama ada
                dalam perbadanan berkaitannya, syarikat sekutunya, ejennya
                dan/atau pihak ketiga;
              </li>
              <br />
              <li>
                untuk mengesan jenayah atau penipuan, dan untuk menggunakan
                danmendedahkan data peribadi tersebut untuk penyiasatan,
                pencegahan, pendakwaandan pematuhan terhadap sekatan, termasuk
                Know Your Customer (KYC) dan tujuanpemeriksaan am mengenai
                Politically Exposed Person (PEP), iaitu Orang YangTerdedah
                Secara Politik;
              </li>
              <br />
              <li>
                untuk tujuan mematuhi keperluan undang-undang dan menghulurkan
                bantuankepada agensi penguatkuasaan undang-undang yang mungkin
                termasuk keperluanpendedahan, pemberitahuan dan penyimpanan
                rekod;
              </li>
              <br />
              <li>
                untuk mengendali dan/atau mengurus produk dan perkhidmatan yang
                ditawarkanoleh Syarikat tersebut dan/atau sebarang perjanjian
                yang mungkin dilakukan antaraanda dengan Syarikat
              </li>
              <br />
              <li>
                untuk menambah baik dan memajukan produk dan perkhidmatan yang
                ditawarkandan diberikan oleh Syarikat tersebut kepada anda dan
                untuk memastikan jaminankualiti yang lebih baik kepada anda;
              </li>
              <br />
              <li>
                untuk menggunakan dan mendedahkan data peribadi tersebut untuk
                penyelidikanpasaran dan analisis statistik dan kaji selidik bagi
                tujuan untuk menambah baikproduk dan perkhidmatan kami serta
                pembangunan produk;
              </li>
              <br />
              <li>
                untuk mengekalkan sejarah kredit anda untuk rujukan semasa dan
                masa depan (jika ada);
              </li>
              <br />
              <li>
                untuk melindungi kepentingan Syarikat tersebut dan tujuan-tujuan
                lain yangberkaitan;
              </li>
              <br />
              <li>
                untuk fungsi dalaman seperti penyelidikan dan pembangunan
                produk, menilai keberkesanan kempen pemasaran dan promosi,
                penyelidikan pasaran, analisisstatistik dan pemodelan,
                pelaporan, audit dan pengurusan risiko serta pencegahanpenipuan;
              </li>
              <br />
              <li>
                untuk membandingkan maklumat/data bagi ketepatan rekod Syarikat,
                danmengesahkan ketepatan maklumat/data tersebut dengan pihak
                ketiga;
              </li>
              <br />
              <li>
                untuk menggunakan data peribadi tersebut bagi tujuan
                penyelidikan, reka bentuk danpenambahbaikan produk dan
                perkhidmatan, proses operasi dan perniagaan kami;
              </li>
              <br />
              <li>
                untuk menyokong perniagaan Syarikat tersebut, pemantauan
                kewangan dan risiko, perancangan dan
              </li>
              <br />
              <li>untuk mengendalikan aduan dan pertanyaan</li>
              <br />
              <li>
                untuk memindahkan atau menyerahkan hak dan tugas kami di bawah
                sebarangterma dan syarat yang mengawalkan urusan antara kami dan
                anda;
              </li>
              <br />
              <li>
                untuk menggunakan dan mendedahkan data peribadi tersebut bagi
                tujuankeselamatan;
              </li>
              <br />
              <li>
                sebarang tujuan yang berkaitan dengan transaksi dan/atau
                perjanjian yang dimasuki atau akan dimasuki oleh anda dan/atau
                majikan utama anda
              </li>
              <br />
              <li>
                tujuan-tujuan lain yang secara munasabah, dianggap sesuai oleh
                Syarikat tersebut.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>3. Pendedahan Data Peribadi</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={3}>
              <li>
                1. Data Peribadi anda yang dikumpul, digunakan, diproses,
                dikendalikan dan/ataudisimpan oleh Syarikat tersebut akan dijaga
                kerahsiaannya pada setiap masa. Bagi tujuan seperti yang
                diterangkan di sini, Syarikat tersebut bolehmendedahkan,
                berkongsi dan/atau memindahkan Data Peribadi anda kepada: –
                <br />
                <ol type="a">
                  <li>
                    Ejen-ejen, syarikat induk, subsidiari, rakan kongsi dan
                    syarikat berkaitanSyarikat tersebut;
                  </li>
                  <br />
                  <li>
                    Mana-mana ejen, kontraktor, pembekal perkhidmatan pihak
                    ketiga, penasihat profesional atau broker yang berkaitan,
                    atas dasar perlu-diketahui (need-to-know basis) untuk
                    tujuanmembekalkan perkhidmatan/nasihat mereka kepada
                    Syarikat tersebut;
                  </li>
                  <br />
                  <li>
                    Mana-mana biro kredit, agensi pelaporan kredit, agensi
                    rujukan kredit, agensi penarafan, syarikat insurans, broker
                    insurans, agensi pemungutanhutang dan/atau pihak lain yang
                    membantu dalam fungsi pemulihan hutangbagi transaksi yang
                    dilakukan di bawah
                  </li>
                  <br />
                  <li>
                    Mana-mana pembekal perkhidmatan kewangan berkaitan dengan
                    produkdan perkhidmatan yang anda ada dengan Syarikat
                    tersebut;
                  </li>
                  <br />
                  <li>
                    Mana-mana bakal atau perserta, penerima hak dan/atau
                    tanggungjawabsebenar Syarikat tersebut di bawah mana-mana
                    transaksi antara Syarikat tersebut dan anda;
                  </li>
                  <br />
                  <li>
                    Mana-mana penjamin atau pembekal jaminan bagi produk
                    dan/atauperkhidmatan yang diberikan oleh Syarikat tersebut
                    kepada anda;
                  </li>
                  <br />
                  <li>
                    Mana-mana badan atau pihak berkuasa, termasuk pengawal selia
                    asinguntuk pelaksanaan fungsi mereka, atau mana-mana pihak
                    yangdikehendaki oleh undang-undang atau mana-mana kerajaan,
                    kerajaansepara, pentadbiran, mahkamah atau tribunal;
                  </li>
                  <br />
                  <li>
                    Rakan kongsi strategik/perniagaan yang mempunyai hubungan
                    dengankami untuk produk dan perkhidmatan tertentu;
                  </li>
                  <br />
                  <li>
                    mana-mana orang yang berkaitan dengan penguatkuasaan
                    ataupemeliharaan mana-mana hak kami di bawah perjanjian anda
                    dengan kami; dan atau
                  </li>
                  <br />
                  <li>
                    Mana-mana pihak yang diberi kuasa dan/atau disetuju oleh
                    anda.
                  </li>
                </ol>
              </li>
              <br />
              <li value={3}>
                2. Anda dengan ini bersetuju bahawa Data Peribadi anda yang
                dikumpul olehSyarikat tersebut boleh dari semasa ke semasa
                didedahkan oleh Syarikat tersebut kepada pihak ketiga termasuk
                tetapi tidak terhad kepada Vendor Syarikat tersebut untuk
                operasi server atau pemprosesan data bagi tujuan yangdinyatakan
                di sini (Tujuan Yang Dibenarkan). Walaupun Syarikat tersebut
                tidakmempunyai kawalan penuh ke atas pengurusan sebenar Data
                Peribadi olehpihak ketiga, Syarikat tersebut berusaha untuk
                mengambil semua langkah yangmunasabah untuk memastikan tahap
                perlindungan yang sebanding diberikanoleh pihak ketiga yang
                berkaitan terhadap Data Peribadi anda, khususnya, mematuhi PDPA
                dan dasar serta garis panduan yang berkaitan.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>4. Keselamatan Data Peribadi</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={4}>
              <li>
                1. Syarikat tersebut mengambil keselamatan Data Peribadi anda
                dengankepentingan yang tertinggi. Syarikat tersebut menyimpan,
                melindungi, memproses, dan menjaga keselamatan Data Peribadi
                anda denganmengekalkan perlindungan fizikal, elektronik, dan
                prosedural yang berpatuhandengan undang-undang. Syarikat
                tersebut akan, sejauh yang praktikal, berusaha untuk mencegah
                sebarang akses yang tanpa kebenaran dan/atautidak sah,
                pemprosesan yang tidak wajar, serta kehilangan, pemusnahan,
                ataukerosakan yang tidak disengajakan terhadap Data Peribadi
                anda.
              </li>
              <br />
              <li value={4}>
                2. Walaupun terdapat pautan pihak ketiga dalam Laman Web
                tersebut yangmengarahkan pengguna ke laman web pihak ketiga,
                sila ambil perhatianbahawa Syarikat tersebut tidak mempunyai
                kawalan dan tidak akan bertanggungjawab terhadap dasar privasi
                dan perlindungan keselamatanberkaitan dengan Data Peribadi yang
                dikumpul melalui laman web pihak ketigatersebut. Anda
                dinasihatkan untuk mengesahkan notis privasi yang berkuat kuasa
                bagi laman web berkenaan dan untuk menghubungi
                pemilik/pengendali laman web tersebut sekiranya terdapat
                sebarang keraguan berkenaan dengannotis privasi laman web yang
                berkenaan tersebut.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>5. Kegunaan Cookies</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Sebagai sebahagian daripada proses pengumpulan data, Syarikat
            tersebut bolehmenggunakan Cookies dan teknologi serupa lain untuk
            mengumpul maklumat andaapabila anda melawat atau menggunakan Laman
            Web tersebut termasuk saluran lainseperti aplikasi mudah alih
            (secara kolektif dipanggil "Laman"(Site)) sebagai sebahagiandaripada
            peningkatan lanjut terhadap Laman Web tersebut, Laman tersebut
            danpengalaman anda dalam menggunakan Laman Web dan/atau Laman
            tersebut.
            <ol start={5}>
              <li>
                1. Apa itu ‘Cookie’?
                <br />
                <br />
                “Cookies” adalah fail data kecil yang pelayar web (‘web
                browser’) anda letakkanpada komputer atau peranti (device) anda.
                “Cookie” membantu anda untukmenyemak imbas laman web yang anda
                layari dan cookies sendiri tidak dapat mengutip apa-apa maklumat
                yang disimpan pada komputer atau fail anda. Apabila pelayar web
                (server) menggunakan pelayar web untuk membaca“cookies”, mereka
                boleh membantu laman web untuk memberikan perkhidmatanyang lebih
                mesra pengguna. Untuk melindungi privasi anda, pelayar web
                andahanya memberikan akses laman web untuk cookie yang telah
                dihantar kepadaanda.
              </li>
              <br />
              <li value={5}>
                2. Kenapa kita menggunakan ‘Cookies’?
                <br />
                <br />
                Cookies digunakan oleh Syarikat tersebut untuk lebih memahami
                tingkah lakudan pilihan pengguna melalui interaksi pengguna di
                Laman Web dan/atau Lamantersebut, untuk menambah baik pengalaman
                pengguna dalammenggunakanLaman Web dan/atau Laman Web tersebut.
              </li>
              <br />
              <li value={5}>
                3. Cookies Pihak Ketiga
                <br />
                <br />
                Laman Web dan/atau Laman ini mungkin mengandungi pautan yang
                membawaanda kepada laman web pihak ketiga dan Cookies yang
                terguna dalamlamanweb/laman pihak ketiga tersebut adalah
                disesuaikan dan dikawalkan olehpihak-pihak ketiga tersebut
                secara masing-masing. Syarikat tersebut tidakmempunyai kawalan
                ke atas Cookies tersebut yang digunakan dan tidak
                akanbertanggungjawab terhadap sebarang kesan dan/atau kesan yang
                disebabkanatau bersampingan dengan penggunaan Cookies tersebut
                oleh laman web pihakketiga. Anda digalakkan dan dinasihatkan
                untuk menyemak penggunaanCookies oleh laman web pihak ketiga
                tersebut.
              </li>
              <br />
              <li value={5}>
                4. Bagaimanakah saya menolak dan memadam Cookies?
                <br />
                <br />
                Jika anda berhasrat untuk menutup dan memadam kebenaran
                untukmenggunakan Cookies dalam Laman Web dan/atau Laman
                tersebut, anda bolehmelaksanakan tindakan tersebut dengan
                mengikut arahan yang ditetapkan olehsetiap pelayar.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            6. Hak Untuk Mengakses dan Meminda/Mengemaskini Data Peribadi
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={6}>
              <li>
                1. Anda boleh pada bila-bila masa meminta untuk mengkaji semula
                dan/ataumengakses Data Peribadi dengan permintaan bertulis
                kepada Syarikat tersebut;
              </li>
              <br />
              <li value={6}>
                2. Syarikat tersebut berkomitmen untuk memastikan bahawa Data
                Peribadi yangkami miliki adalah tepat, lengkap, tidak
                mengelirukan dan terkini. Jika anda inginmengkaji semula,
                mengubah atau menghapuskan butiran yang anda telahmembekalkan
                kepada kami, anda boleh memohon untuk mengakses, memindaatau
                membuat sebarang pembetulan kepada Data Peribadi anda
                denganmengemukakan permintaan bertulis kepada Syarikat;
              </li>
              <br />
              <li value={6}>
                3. Walaupun akses telah diberikan kepada anda atau
                sebarangpembetulan/pemuktahiran Data Peribadi anda telah
                dilakukan, Notis Privasi ini akan terus kekal dan berkesan pada
                setiap masa.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            7. Penarikbalikan Persetujuan{" "}
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            <ol start={7}>
              <li>
                1. Persetujuan yang anda berikan kepada Syarikat tersebut
                berkenaan denganpengumpulan, penggunaan, dan pendedahan Data
                Peribadi anda akan kekal sah sehingga ia ditarik balik oleh anda
                secara bertulis. Penarikbalikanpersetujuan ini tertakluk kepada
                obligasi kontrak anda untuk mendedahkanmaklumat tertentu di
                bawah Perjanjian dan/atau transaksi yang dilakukandi Laman Web
                tersebut dan/atau sebarang keperluan undang-undang
                atauperaturan.
              </li>
              <br />
              <li value={7}>
                2. Syarikat tersebut berhak mengambil langkah-langkah yang
                diperlukan untukmelindungi haknya jika ia berpendapat bahawa
                penghapusan atau penghentianpersetujuan oleh anda menghalang
                Syarikat tersebut daripada menyediakanatau terus menyediakan
                produk dan perkhidmatan atau memenuhi obligasi Syarikat tersebut
                dengan cara yang memuaskan. Ini termasuk hak Syarikat tersebut
                untuk enggan menyediakan atau menghentikan penyediaan
                sebarangkemudahan, produk, atau perkhidmatan kepada anda.
              </li>
              <br />
              <li value={7}>
                3. Anda perlu memberikan masa yang munasabah kepada Syarikat
                untukpenarikbalikan dan/atau penghapusan Data Peribadi tersebut
                atas permintaanbertulis anda.
              </li>
            </ol>
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>8. Pemindahan Data Peribadi</Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Anda berhak meminta Syarikat tersebut untuk pemindahan Data Peribadi
            anda kepadapihak ketiga pilihan anda melalui permohonan bertulis
            kepada Syarikat tersebut. Silaambil perhatian bahawa permintaan
            untuk memindahkan Data Peribadi anda hanya bolehdilakukan di mana
            dan apabila boleh dilaksanakan secara teknikal dan menurut
            undang-undang dan peraturan. Syarikat tersebut tidak
            bertanggungjawab ke ataskeselamatan Data Peribadi atau pemprosesan
            yang sama setelah diterima oleh pihakketiga. Kami memelihara hak
            kami untuk menolak pemindahan sekiranya pemindahantersebut akan
            mengganggu hak orang lain (mis. di mana pendedahan Data Peribadi
            yangkami pegang tentang anda akan mendedahkan maklumat tentang orang
            lain)
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            9. Perubahan kepada Dasar Privasi{" "}
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Syarikat tersebut boleh mengikut budi bicara mutlaknya mengemas
            kini, menyemakdan/atau meminda Notis Privasi pada bila-bila masa
            dengan menyiarkan Notis Privasi yang dikemas kini di Laman Web
            tersebut. Penggunaan berterusan anda terhadap Laman Web tersebut dan
            Perkhidmatan yang diberikan di atasnya akan dianggapsebagai
            penerimaan sebarang kemas kini, semakan atau pindaan yang dibuat
            pada NotisPrivasi.
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>
            10. Persetujuan dan Pengakuan
          </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Dengan akses berterusan anda dan penggunaan Laman Web tersebut dan
            langganankepada Perkhidmatan yang diberikan padanya, anda dengan ini
            mengakui bahawa andatelah membaca dan memahami kandungan Notis
            Privasi ini dan dengan itu bersetujudengan pengumpulan, penggunaan,
            pemprosesan dan pendedahan Data Peribadi andaseperti di sini
            sepertimana yang ditetapkan dalam dan menurut PDPA dan
            mana-manaundang-undang lain yang berkaitan.
          </Text>
        </Container>

        <Container
          className={`${classes.Container} ${classes.LeftTitleContainer}`}
        >
          <Title className={classes.Title2}>11. Hubungi </Title>
        </Container>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Sekiranya anda mempunyai sebarang pertanyaan berkenaan dengan Notis
            Privasi Laman Web kami, sila menghubungi kami untuk sebarang
            pertanyaan atau komendengan menghantar e-mel kepada kami di{" "}
            <a href="https://domaxcredit.co/">Domax Credit</a>{" "}
          </Text>
        </Container>
      </Container>

      <Footer />
    </div>
  );
}
