import { Title, Text, Container, Button, Overlay } from "@mantine/core";
import classes from "./ServicesHero.module.css";
import { HeaderTransparent } from "../Static/HeaderTransparent";
import { Link } from "react-router-dom";

export function ServicesHero() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <HeaderTransparent />
      </div>

      <Overlay
        color="#000"
        opacity={0.85}
        zIndex={1}
        className={classes.overlay}
      />

      <div className={classes.inner}>
        <Title className={classes.title}>
          Working Capital Financing
          <Text component="span" inherit className={classes.highlight}></Text>
        </Title>

        <Text size="sm" className={classes.bydomax}>
          By Domax
        </Text>

        <Container size={840}>
          <Text className={classes.description}>
            Get the working capital you need to grow your business and seize
            opportunities. Domax offers flexible financing solutions tailored to
            your unique needs.
          </Text>
        </Container>

        <div className={classes.controls}>
          <Button
            className={classes.control}
            variant="white"
            size="lg"
            component={Link}
            to="/contactus"
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
}
