import React from "react";
import classes from "./term.module.css";
import { Text, Title, Box, Container } from "@mantine/core";
import { HeaderWhite } from "../Static/HeaderWhite";
import { Footer } from "../Static/Footer";

export default function Term() {
  return (
    <div>
      <HeaderWhite />
      <Container className={classes.term}>
        <Title className={classes.Title}>TERMS AND CONDITIONS</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            The Terms and Conditions set out herein are the general terms which
            governs the use of{" "}
            <a href="https://domaxcredit.co/">Domax Credit</a>
            (hereinafterreferred to as “the Website”) and the contents contained
            therein by the guests or the registered users thereof (hereinafter
            referred to generally as “you”). The access to or use of this site
            shall be subject to the following Terms and Conditions. By accessing
            the site and continuing to do the same, you agree to be bound by
            these conditions, which may be updated by us from time to time
            without notice. If you do not agree to any of the conditions, please
            do not use or browse the site or any of its pages. Your continued
            access or use of this site and/or the services provided herein after
            an update by us will constitute your acceptance of and agreement to
            be bound by the updated conditions.
          </Text>
        </Container>

        <Title className={classes.Title}>Amendments</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Domax Credit (M) Sdn. Bhd. (hereinafter referred to as “the
            Company”) reserves its right to amend, revise, and/or modify these
            Terms and Conditions from time to time at its absolute discretion
            without the need of further reference to you. Any such amended,
            revised and/or modified Terms and Conditions shall be uploaded to
            the Website followed the publishing of a written notice or
            announcement to such effect of informing the guests or registered
            users of the Website of such amendment, revision and/or modification
            of the same. Any such amendment, revision and/or modification shall
            be effective immediately at the time of the uploading of the same
            and the notice or announcement thereof. You are required therefore
            to regularly review the information made available on the Website
            before your continued access and usage of the Website during each
            time of your visit to the Website. Your continued use of the Website
            shall indicate your unequivocal acceptance to be bound by the
            amended, revised and/or modified Terms and Conditions.
          </Text>
        </Container>

        <Title className={classes.Title}>Disclaimer - Information</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            All information contained herein and/or posted or published hereon
            is provided on an “as is” basis. While the Company exercises due
            diligence and endeavours in ensuring the accuracy, adequacy and/or
            completeness of the information contained herein, neither the
            Company, its Directors, officers, employees nor agents expressly or
            impliedly warrant the accuracy, adequacy and/or completeness of the
            same.
            <br />
            <br />
            The Company and/or its Directors, officers, employees and/or agents
            does not in any manner hold itself and/or themselves as advising you
            with respect to any information on the Website. The Company and/or
            its Directors, officers, employees and/or agents neither expressly
            nor impliedly represents and/or warrants to you that the information
            herein and/or any part thereof as being suitable and/or applicable
            for the purpose for which it is relied on, or any product or
            information thereof is fit for a particular purpose. As such, you
            shall seek and is at all times deemed to have sought independent
            professional advice with respect to the information and/or materials
            contained herein prior to the making of any decision in reliance of
            any such information and/or materials.
            <br />
            <br />
            Under no circumstances shall the Company be liable for any loss or
            damages howsoever arising, whether in contract, tort, negligence or
            otherwise in connection with your access or use of this site, or
            reliance on any information, materials or services provided on this
            site.
          </Text>
        </Container>

        <Title className={classes.Title}>Registration</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            To further access the Website and/or to subscribe to the Services
            provided hereunder, youarerequired to register yourself as a member
            of the Website.
            <br />
            <br />
            To register yourself as a member of the Website, you must be at
            least eighteen (18) yearsof age at the time of registration. You
            shall be required to furnish all necessary information includingbut
            not limited to your full name as listed in your NRIC (if Malaysian)
            and/or any other relevant identity documentations, your date of
            birth, your bank account details, your contact detailsand/orall
            other information as can be from time to time requested by the
            Company through theWebsiteat the time of registration by clicking on
            the “Sign Up” button and by filling up all necessary forms
            thereafter.
            <br />
            <br />
            By so registering as a member of the Website, you hereby undertake
            and warrant astothetruthfulness, accuracy, validity, completeness
            and reliability of all information disclosed, submitted to the
            Company through the Website at the time of the disclosure. You shall
            befurtherdeemed to have represented to the Company that all such
            information furnishedarenot misleading nor violate any laws or
            regulations, by omission or otherwise at the timeof suchdisclosure.
            In the event of any change of circumstances with respect to the
            information provided, you are responsible to inform the same to the
            Company at the soonest time possible toenablethe necessary
            communication and/or amendment in the Company’s records to be
            effected.
          </Text>
        </Container>

        <Title className={classes.Title}>Data Processing and Security</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            By so registering as a member of the Website, you are deemed to have
            consented and agreedtothe processing, usage and/or storing of all
            data collected from and disclosed by you throughtheWebsite by the
            Company in accordance of the Privacy Notice contained in the
            Website.
            <br />
            <br />
            You as a registered member of the Website shall be responsible in
            ensuring the securityof thelogin credentials and password created
            for the purpose of creation of an account under theWebsite. For the
            purpose of ensuring the security of your account and data stored
            hereunder, you are put at strict obligation at all times to keep
            your login credentials and password privateandconfidential.
            <br />
            <br />
            In the event of suspected or actual misuse or unauthorised use of
            your account, fraudulent activity, and/or any breach of security of
            the Website, you shall notify the Company of suchevent immediately.
            The Company reserves the right to take action which it deems
            appropriate, including but not limited to requiring you to change
            your password, requiring you toprovidefurther account verification,
            and/or to suspend your account upon receiving notificationfromyouof
            any suspected or actual misuse or unauthorised use of your account,
            fraudulent activity, and/orany breach of security of the Website or
            any intention or actions that disrupts the Website
          </Text>
        </Container>

        <Title className={classes.Title}>Third Party Link</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Should you leave this site via a link contained in the site to
            websites that are not ownedor operatedby the Company, you do so at
            your own risk. The Company does not control such linkedwebsitesand
            therefore shall not be responsible and makes no warranties in
            respect of the content of suchlinked websites, any third parties
            named therein or their products or services. The Companyshall not be
            responsible or liable for damages or losses arising from your access
            to and/or useofsuch linked websites.
          </Text>
        </Container>

        <Title className={classes.Title}>Copyright and Trademark</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            The Company owns all rights in the Website and all components
            thereof including without limitation, all text, information,
            materials, structure, sequence and organisation of the pages and
            screens. Nopart of the Website may be copied, reproduced,
            republished, uploaded, posted, transmitted, distributed in any form
            or manner (including electronic, mechanical, photocopy,
            recordingorotherwise) or by any means (including storage in any
            retrieval system of any nature) without theprior written permission
            of the Company. Modification of the Website or any part thereof or
            useof any materials contained therein for any purpose is a violation
            of copyright law and other proprietaryrights.
          </Text>
        </Container>

        <Title className={classes.Title}>Copyright and Trademark</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            By accepting the Terms and Conditions, you shall not in any way use
            the Website in the respect that:
            <br />
            <br />
            (a) is in breach of the Term and Conditions, policies or any other
            agreement entered with us;
            <br />
            <br />
            (b) Amounts to or is tantamount to the conduct of fraudulent,
            criminal or unlawful acts on the Website;
            <br />
            <br />
            (c) Misuses the website in an obscene, indecent, vulgar, profane,
            racial, sexist, offensive, discriminatory, derogatory, harmful,
            unlawful, threatening, harassing, embarrassing, malicious, abusive,
            hateful, political, defamatory manner;
            <br />
            <br />
            (d) Violates any laws and/or regulations which are in force at any
            relevant point of time with respect to the use of the Website and
            applicable under jurisdiction of Malaysia;
            <br />
            <br />
            (e) Impersonates or misrepresents any person or body;
            <br />
            <br />
            (f) Infringes or breaches the copyright or property rights;
            <br />
            <br />
            (g) Distributes, or discloses any private information of any third
            party;or
            <br />
            <br />
            (h)Refrains or restricts any other person from the use and/or
            enjoyment of the forumontheWebsite.
            <br />
            <br />
            We reserve the right to suspend and/or terminate your account if we
            have reasonable belief that you have breached or suspected to have
            breached any of the above mentioned.
          </Text>
        </Container>

        <Title className={classes.Title}>Non-Liability</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            You shall access and use the Website and any contents, information,
            services renderedthereonsolely and strictly at your own risk. The
            Website and the use of the same is renderedbytheCompany on an “as
            is, where is” and “as available” basis, the Company in no way
            guarantees, warrants and/or represents whether expressly or
            impliedly that the contents of the Websitearecomplete, accurate,
            up-to-date, fit for a particular purpose, error-free and, to the
            extent permittedby law. As such, the Company shall in no way accept
            and/or to be deemed to have acceptedanyliability arising from any
            error and/or omission arising from the contents of the Website.
            <br /> <br />
            The Company further limits its liability with respect to the
            unavailability, interruption, delay, error, occurrence of viruses
            and/or other harmful or invasive elements with respect to the
            operationof the Website. The Company does not guarantee and in no
            way has control over the compatibilityof the Website with the
            hardware and software of the device which may be used by you
            toaccessthe Website. You shall be solely responsible in ensuring
            that your device is compatibleandcompetent for the purpose of
            accessing and use of the Website. The Company shall in nowaybeliable
            for damage to, viruses and/or any invasive codes which might cause
            harmor affect yourdevice(s) as a result of your use or access of the
            Website.
            <br /> <br />
            This exclusion and limitation of the Company’s liability with
            respect to the use and accessof theWebsite shall be effective at any
            point of time and shall survive in the event you cease accessoruse
            of the Website.
          </Text>
        </Container>

        <Title className={classes.Title}>Indemnity</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            You agree to defend, indemnify and hold the Company, its Directors,
            officers, representatives, employeesand/or its affiliates harmless
            from and against all liabilities, damages, claims, actions,
            costsandexpenses in connection with any claim by a third party
            arising out of or incidental to :-
            <br /> <br />
            (a) The use of the Website and/or any contents thereof by you and/or
            your representatives, affiliates and/or any related parties in use
            of the Website through and by your Account, whether or not in breach
            of the Terms and Conditions and Privacy Notice contained herein;
            <br /> <br />
            (b) The breach of the Terms and Conditions and/or Privacy Notice
            contained hereinbyyouand/or your representatives, affiliates and/or
            any related parties in use of the Websitethroughand by your Account;
            and/or
            <br /> <br />
            (c) any non-compliance with or violation of any prevailing laws and
            regulations, government’s policies, directives and court verdicts or
            arbitral awards of any judicial bodies including infringement of
            anythird party’s rights committed by or which involves you and/or
            your affiliates, employees or relatedparties in or after the use of
            our services or during the access to, use of or browsing in
            theWebsite.
          </Text>
        </Container>

        <Title className={classes.Title}>Duty of Confidentiality</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Being a licensee to the Website and the contents and information
            contained therein, youasaMember of the Website are entitled to
            access and use all information and content containedtherein for your
            personal purposes including but not limited to the purpose of
            determinationandassessment of an investment decision and/or issuance
            of an application to raise fund throughtheWebsite subject to the
            duty to confidentiality as follows :-
            <br /> <br />
            You as a Member of the Website, shall at all times during and after
            the cease of usageof theWebsite, maintain and keep maintained the
            confidentiality of the Confidential Informationwhichincludes but not
            being limited to the following information :-
            <br /> <br />
            <ol type="a">
              <li>
                The Internal operating systems and procedures adopted by
                theCompany;
              </li>
              <br />
              <li>
                All and any agreements, declaration, documents furnished to you
                through the Websiteand/orincidental to your use of the Website;
              </li>
              <br />
              <li>
                Any information disclosed with respect to any potential
                investors, actual investorsand/orlisted issuers; and
              </li>
              <br />
              <li>
                All such information and materials which can be from time to
                time listed and notifiedbytheCompany to be private and
                confidential information.
              </li>
            </ol>
            You shall at all times during and after the use of the Website
            perform and observe or causedtobeperformed and observed the Duty of
            Confidentiality stipulated herein, except that such Confidential
            Information may be disclosed :-
            <br />
            <br />
            <ol type="i">
              <li>
                To your directors, officers, partners, employees and agents,
                including accountants, legal counsel and other advisors (it
                being understood that the Persons to whomsuchdisclosureis made
                will be informed of the confidential nature of such Confidential
                Informationandinstructed to keep such Confidential Information
                confidential);
              </li>
              <br />
              <li>ii. To the extent requested by any regulatory authority;</li>
              <br />
              <li>
                To the extent required by applicable laws or regulations or by
                any subpoena or similar legal process;
              </li>
              <br />
              <li>
                In connection with the exercise of any remedies hereunder or any
                suit, actionor proceeding relating to the use of the Website or
                the enforcement of rights hereunder;
              </li>
              <br />
              <li>
                With the consent of the Disclosing Party, ie: the Company; or
              </li>
              <br />
              <li>
                To the extent such Confidential Information
                <ol type="a">
                  <li>
                    becomes publicly available other than as a result of a
                    breach of the TermsandConditions or
                  </li>
                  <li>
                    becomes available to the Receiving Party (You) on a
                    nonconfidential basisfromasource other than the Disclosing
                    Party (The Company).
                  </li>
                </ol>
              </li>
            </ol>
            Any Person required to maintain the confidentiality of Confidential
            Information as providedhereunder shall be considered to have
            complied with its obligation to do so if such Personhasexercised the
            same degree of care to maintain the confidentiality of such
            Confidential Informationas such Person would accord to its own
            confidential information.
          </Text>
        </Container>

        <Title className={classes.Title}>
          Personal Data Protection Act 2010 (PDPA)
        </Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            By visiting this Website, you hereby agree that the Company may
            collect, obtain, store and processyourpersonal data that you provide
            in this Website for the purpose of the transactions and
            dealingsbetweenyourself and the Company intended to be conducted
            through the Website or otherwise and/or anyrelated transactions and
            dealings in connection with the nature of the Company’s business,
            receivingupdates, news, promotional and marketing mails or materials
            from the Company
            <br /> <br />
            You hereby give your consent to the Company to:-
            <ol type="i">
              <li>Store and process your Personal Data; and</li>
              <br />
              <li>
                Disclose your Personal Data to the relevant governmental
                authorities or third parties whererequiredby law or for legal
                purposes
              </li>
            </ol>
            In addition, your personal data may be transferred to any company
            within the group which mayinvolvesending your data to a location
            outside Malaysia. For the purpose of updating or correctingsuchdata,
            you may at any time apply to the Company to have access to your
            personal data which are storedbytheCompany.
            <br /> <br />
            For the avoidance of doubt, Personal Data includes all data defined
            within the Personal DataProtectionAct 2010 including all data you
            had disclosed to the Company in this Website. Please visit
            andreadthePrivacy Notice for more detailed Terms with respect to the
            processing of personal data
          </Text>
        </Container>

        <Title className={classes.Title}>Termination of Membership</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            You may suspend and/or terminate your membership under the Website
            at any point of timebygiving prior written notice to the Company
            informing of such intention of terminate. Suchwrittennotice can be
            sent to the Company either by way of email to the Company’s email
            addressasprovided in the Website and/or by post to the Company at
            its address as provided in theWebsite.
            <br /> <br />
            The Company may at any time, suspend, terminate and/or cancel your
            account with immediateeffect at its absolute discretion and/or in
            the event a breach of any of the Terms and Conditionshas been
            committed by you. The Company shall inform and notify such
            suspension, terminationand/or cancellation of membership to you by
            written notice by email to the email addressasprovided by you during
            your registration as a Member of the Website.
            <br />
            <br />
            The suspension or cancellation of your account and your right to use
            the Website shall not affect either party’s statutory rights or
            liabilities nor shall it be deemed or construed as a waiver of
            ourrights to take any action against you for any loss and damages
            suffered as a result of your breachof these Website Terms.
          </Text>
        </Container>

        <Title className={classes.Title}>Severance</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            If any provision contained herein is prohibited by law or judged by
            a court to be unlawful, voidorunenforceable, the provision shall, to
            the extent required, be severed from the Terms andConditionsand
            rendered ineffective as far as possible without modifying the
            remaining provisions, andshall not inany way affect any other
            circumstances of or the validity or enforcement of the Terms and
            Conditions. The Company shall be entitled to unilaterally decide in
            its sole and absolute discretion, asubstituteprovision, without
            having to notify or secure any approval of you, which is valid and
            enforceableandachieves to the greatest extent possible the economic,
            legal and commercial objectives of suchillegal, void, invalid,
            prohibited or unenforceable term, condition, stipulation, provision,
            covenant orundertaking.
          </Text>
        </Container>

        <Title className={classes.Title}>Electronic Communication</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            By your continued access and usage of the Website, you hereby agree
            and consent totheuseof electronic communication as the mode and
            method of communication for the purposes of transactionsand dealings
            including but not limited to the entering of Agreement with respect
            to the grantingof financing facility by the Company under the
            Moneylenders Act 1951 and/or any other relateddealingswhich is
            incidental to the use of the Website in accordance to the Electronic
            Commerce Act 2006.
          </Text>
        </Container>

        <Title className={classes.Title}>Governing Law</Title>
        <Container className={classes.Container}>
          <Text className={classes.Description}>
            The Terms and Conditions shall be governed by, construed and
            enforced by virtue of thelawsof Malaysia. You further agree to
            submit to the exclusive jurisdiction of the Malaysian courts.
          </Text>
        </Container>

        <Title className={classes.Title}>Complaints</Title>
        <Container className={classes.Container}>
          <Text className={classes.Description}>
            You may reach us at{" "}
            <a href="https://domaxcredit.co/">Domax Credit</a> in order to voice
            out on any feedback and/or complaint withrespect to the use of the
            Website and/or the Services rendered by the Company.
          </Text>
        </Container>

        <Title className={classes.Title}>Acknowledgement</Title>
        <Container className={classes.Container}>
          <Text className={classes.Description}>
            You hereby acknowledge that you shall use and access the Website and
            the Services providedthereonat your own risk. The Company in does
            not and will not warrant or represent, and thereforewill not
            indemnify nor provide any forms of compensation whatsoever to you,
            whether expressly or impliedly, with respect to the information and
            contents of the Website as mentioned in the Disclaimer aboveand:-
            <ol type="a">
              <li>
                that any and all information on its platform, website, marketing
                material or inanydocumentation that the Company prepared
                including the Contents are accurate, valid, correct, current,
                reliable, verified, complete, or error-free;
              </li>
              <br />
              <li>
                that the Website (including any functions associated therewith)
                or the Contentswill beuninterrupted or error-free, or that
                defects will be corrected, or that the Website or its server is
              </li>
              <br />
              <li>
                or will be free of viruses, worms and/or other harmful or
                invasive elements, or that thedownload, installation or use of
                any software of the Website or the Contents in or
                withanycomputer or device will not affect the functionality or
                performance of the computer or device;
              </li>
              <br />
              <li>
                that the Website will be protected against any malicious attack
                or that data will not becompromised under such attack;
              </li>
              <br />
              <li>
                as to the functionality, operability, accessibility, accuracy,
                correctness, completeness, reliability,up-to-dateness,
                timeliness, satisfactory quality, merchantability, fitness for a
                particular purpose, and non-infringement of proprietary rights,
                of the Website and the Contents;
              </li>
              <br />
              <li>
                the success of any outcome, dealings or transaction on the
                Website; and
              </li>
              <br />
              <li>
                the approval and granting of any applications made under the
                Website.
              </li>
              <br />
            </ol>
            You further acknowledge that the Company in no way assumes and
            undertakes any advisory, fiduciaryor any other similar other duties
            or act as an investment advisor to you with respect to the
            informationand materials published on the Website and the Services
            rendered herein. You hereby acknowledgeand represent that you have
            taken, or will take, the necessary independent legal, tax, financial
            or otheradvice before using any function or Services rendered on the
            Website.
          </Text>
        </Container>

        <Title className={classes.Title}>Non-Assignability</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            Your Account under the Website, together with your rights and/or
            duties arising thereunder arenot assignable or transferable by you
            to any other person, except by will or by the applicablelawsof
            distribution.
            <br />
            <br />
            The Company may assign or transfer its rights under the Terms and
            Conditions to another Companywhere it reasonably believes that such
            assignment and transfer will not affect your rights as theuser/
            Member of the Website.
          </Text>
        </Container>

        <Title className={classes.Title}>Non-Waiver</Title>

        <Container className={classes.Container}>
          <Text className={classes.Description}>
            No failure or delay by the Company to exercise any right or remedy
            provided under the TermsandConditions against you in the event of
            any breach of the same shall constitute a waiver of that or anyother
            right or remedy, nor shall it prevent or restrict the exercise of
            that or any other right or remedyagainst you. No single or partial
            exercise of such right or remedy shall prevent, restrict and/or
            prejudicethe further exercise of that or any other right or remedy
          </Text>
        </Container>
      </Container>
      <Footer />
    </div>
  );
}
