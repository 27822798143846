import WhatisSME from '../images/What_is_SME.png';
import WhyisSME from '../images/Why_is_SME_Important.png';
import UnlockBusinessPotential from '../images/Unlock_Business_Potential.png';

//import to FeaturesWhy.tsx

const ftwhy = {
    term1: {
        image: WhatisSME,
      },

      term2: {
        title: 'What is SME Financing?',
        description:
          'SME Financing consists of working capital financing or CAPEX financing for short term asset. Financing packages will be structured according to business financial capability and needs.',
        button: 'Apply Now',  
      },

      term3: {
        title: 'Why is it important for SME to start financing?',
        description:
          'It is important for SME to start exploring financing for cash flow stability to be able to meet all the uncertainties and challenges in the industry. Financing can speed up the business expansion per business plan without the wait of cashflow being gradually generated from cumulated profit, which is relatively important at current fast-paced environment.', 
        button: 'Apply Now',  
      },

      term4: {
        image: WhyisSME,
    },

    term5: {
        image: UnlockBusinessPotential,
    },

    term6: {
        title: 'Unlock your Business Potential with Working Capital Financing',
      description:
        'With Capital Booster, you will be able to improve your company’s cash flow. Having additional working capital on hand, allows your company to execute better budgeting projection and will be able to seek for more opportunities to expand your business.',
      button: 'Apply Now',  
    }
    };
  
  export default ftwhy;
  