//import to  ApplyNow.tsx

import ProfitIcon from '../images/profit 1.svg';
import SubmitIcon from '../images/submit 1.svg';
import DocumentsIcon from '../images/documents 1.svg';

const apply = {
    term1: {
        imageUrl: SubmitIcon, 
        title: 'Submit Your Application Online',
        description:'Complete our online application form with your business details and financing requirements.',
      },

      term2: {
        imageUrl: DocumentsIcon,
        title: 'Review and Approval Process',
        description: 'Complete our online application form with your business details and financing requirements.',
      },

      term3: {
        imageUrl: ProfitIcon,
        title: 'Get Funded and Grow Your Business',
        description: "Once approved, you'll receive the funds you need to fuel your business growth.",
      },

    
    };
  
  export default apply;
  