import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, MantineProvider } from '@mantine/core';
import App from './App'; // Replace with your App component path

const theme = createTheme({
  // Your custom theme overrides go here
});

ReactDOM.render(
  <MantineProvider theme={theme}>
    <App />
  </MantineProvider>,
  document.getElementById('root')
);
