import { Text, Box, Stack, rem } from '@mantine/core';
import classes from './ContactIcons.module.css';

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title: React.ReactNode;
  description: React.ReactNode;
}

function ContactIcon({ title, description, ...others }: ContactIconProps) {
  return (
    <div className={classes.wrapper} {...others}>

    <div className={classes.body}>
      <Text size="xs" className={classes.title}>
        {title}
      </Text>
      <Text className={classes.description}>{description}</Text>
    </div>

    </div>
  );
}

const MOCKDATA = [
  { title: 'Email', description: 'info@domax.my' },
  { title: 'Address', description: 'Unit C-01-19, Ground Floor Damen USJ Komersial, Persiaran Kewajipan, Usj 1, 47600 Subang Jaya, Selangor'},

];

export function ContactIconsList() {
  const items = MOCKDATA.map((item, index) => <ContactIcon key={index} {...item} />);
  return <div className={classes.contactContainer}>{items}</div>;
}