// src/strings/loanTerms.js

const domaxFaq = {
  term1: {
    question: "What is the limit of loan amount and tenure offered?",
    answer:
      "The loan limit is within RM 100k – RM 2mil and the tenure offered is within 6 months to 12 months, subject to eligibility and approval.",
  },
  term2: {
    question: "How can Domax help?",
    answer:
      "Domax offers a range of financing solutions tailored to the needs of SMEs. Our streamlined application process and competitive rates makes it easier for businesses to access the funding they need.",
  },
  term3: {
    question: "What are the eligible criteria?",
    answer: (
      <div style={{ whiteSpace: "pre-line" }}>
        a. Malaysia incorporated/registered company and business (Sole
        proprietorship, Joint venture, Limited liability partnership, Limited
        company) that classified as Small Medium Enterprise (SMEs).{"\n"}
        {"\n"}
        b. Business is established for at least 2 years, 24 months.{"\n"}
        {"\n"}
        c. Minimum 1-year existing repayment record. Guarantors' repayment
        record can be considered.{"\n"}
        {"\n"}
        d. Minimum paid-up capital at RM 100k.{"\n"}
        {"\n"}
        e. No on-going legal cases
      </div>
    ),
  },

  term4: {
    question: "How long does the approval takes?",
    answer: "Within 7 working days.",
  },
  term5: {
    question: "Is my information safe and secure?",
    answer:
      "At Domax, we take the security and privacy of your information seriously. We use industry-standard encryption and follow strict data protection protocols to ensure that your data is safe and confidential throughout the financing process.",
  },
  term6: {
    question: "Can I apply online?",
    answer:
      "Yes, you can apply for financing with Domax online. Our user-friendly application form makes it easy to provide the necessary information and documentation. If you have any questions or need assistance, our team is available to help.",
  },
  term7: {
    question: "What are the repayment terms?",
    answer:
      "The repayment terms for can vary depending on the specific product or loan you choose. Our team will work with you to determine the best repayment schedule that aligns with your business s cash flow and financial goals.",
  },
  term8: {
    question: "What if I have bad credits?",
    answer:
      "While a strong credit history can increase your chances of securing SME financing, Domax understands that businesses may face challenges. We take a holistic approach to evaluating applications, considering factors beyond just credit scores. Contact our team to discuss your specific situation",
  },
  term9: {
    question: "Can I use the funds for other purposes?",
    answer:
      "Yes, once you secure SME financing with Domax, you can use the funds for any legitimate business purpose. Whether you need to invest in new equipment, hire additional staff, or expand your operations, our financing solutions are flexible to meet your needs.",
  },
  term10: {
    question: "What documents do I need?",
    answer:
      "The specific documentation required can vary depending on the type of financing and your business s circumstances. Our team will provide you with a checklist of the necessary documents and guide you through the process.",
  },
};

export default domaxFaq;
