import classes from "./WantToKnowMore.module.css";
import { Link } from "react-router-dom";

const WantToKnowMore = () => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2 className={classes.title}>Want to Know More?</h2>
        <p className={classes.description}>
          Like what we do, and want to know more about our services. Feel free
          to contact us by clicking below
        </p>
        <Link to="/contactus" className={classes.button}>
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default WantToKnowMore;
