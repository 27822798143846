import { Text, SimpleGrid, Container, rem, Title } from "@mantine/core";
import classes from "./Features.module.css";
import ft from "../../strings/featuresstring";

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  imageUrl: string;
  title: string;
  description: string;
}

function Feature({
  imageUrl,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <img src={imageUrl} alt="picture" className={classes.icon} />
        <Text className={classes.featuresTitle}>{title}</Text>
        <Text className={classes.featuresDescription}>{description}</Text>
      </div>
    </div>
  );
}

const mockdata = ([] = Object.values(ft));

export function Features() {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);

  return (
    <Container size="full" className={classes.featuresContainer}>
      <Title order={1} className={classes.featuresTitle}>
        Benefits of Financing with Us
      </Title>
      <Text className={classes.featuresDescription}>
        We can help you to achieve your business goal
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={0}>
        {items}
      </SimpleGrid>
    </Container>
  );
}
