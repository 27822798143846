import { Text, SimpleGrid, Container, rem, Title, Button } from "@mantine/core";
import classes from "./ApplyNow.module.css";
import apply from "../../strings/applynowString";
import { Link } from "react-router-dom";

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  imageUrl: string;
  title: string;
  description: string;
}

function Feature({
  imageUrl,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <img src={imageUrl} alt={title} />
        <Text className={classes.title}>{title}</Text>
        <Text className={classes.description}>{description}</Text>
      </div>
    </div>
  );
}

const mockdata = ([] = Object.values(apply));

export function ApplyNow() {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);

  return (
    <Container size="full" className={classes.featuresContainer}>
      <Title order={1} className={classes.featuresTitle}>
        Apply Now in Just a Few Steps
      </Title>
      <Text className={classes.featuresDescription}>
        At domax, we make applying for SME financing simple and hassle-free. Our
        streamlined process ensures quick approvals and funding.
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={50}>
        {items}
      </SimpleGrid>
      <div className={classes.buttonContainer}>
        <Button
          size="lg"
          radius="xl"
          color="#FF5500"
          component={Link}
          to="/contactus"
        >
          Apply Now
        </Button>
      </div>
    </Container>
  );
}
