import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { HeroImageBackground } from '../components/HomePage/HeroImageBackground';
import { Features } from '../components/HomePage/Features';
import WhatsappLogo from '../components/Static/WhatsappLogo';
import { FeaturesWhy } from '../components/HomePage/FeaturesWhy';
import { ApplyNow } from '../components/HomePage/ApplyNow';
import { Footer } from '../components/Static/Footer';
import StillHaveQuestion from '../components/HomePage/StillHaveQuestion';
import BottomPart from '../components/HomePage/BottomPart';


function App() {
  return (
    <MantineProvider>
      
      <div className="App">
        <HeroImageBackground />
        <Features />
        <FeaturesWhy />
        <ApplyNow />
        <BottomPart />
        <StillHaveQuestion />
        <Footer />
        
      </div>
      <WhatsappLogo />
    </MantineProvider>
  );
}

export default App;
