import { Title, Text, Container } from "@mantine/core";
import classes from "./AboutUs.module.css";

export function AboutUsHero() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        <Title className={classes.title}>About Domax</Title>

        <Container size={1100}>
          <Text className={classes.description}>
            Domax is a trusted financing platform that integrates extensive
            business financing knowledge to facilitate loans for Small and
            Medium Enterprises (SMEs). As a legally licensed money lending
            company, we extend a financial helping hand to those who need it,
            offering in-house financing solutions that augment SMEs' working
            capital, address cash flow challenges, and facilitate business
            expansion with access to low-interest funds. We offer high loan
            amounts tailored to serve clients financial needs, empowering
            clients to stay ahead of the curve and turn their dreams of a
            prosperous future into reality through financing opportunities with
            Domax. Obtaining a loan from Domax is simple and convenient, thanks
            to our fast approval system and user-friendly online loan
            application process.
          </Text>
        </Container>
      </div>
    </div>
  );
}
