import { MantineProvider } from "@mantine/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@mantine/core/styles.css";
import MainPage from "./NavLink/MainPage";
import AboutUsPage from "./NavLink/AboutUsPage";
import ServicesPage from "./NavLink/ServicesPage";
import ContactUsPage from "./NavLink/ContactPage";
import FaqRealPage from "./NavLink/FaqRealPage";
import Term from "./components/ContactPage/term";
import Privacy from "./components/ContactPage/privacy";

function App() {
  return (
    <MantineProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/contactus" element={<ContactUsPage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/faq" element={<FaqRealPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/termsandconditions" element={<Term />} />
            <Route path="/privacypolicy" element={<Privacy />} />
          </Routes>
        </BrowserRouter>
      </div>
    </MantineProvider>
  );
}

export default App;
