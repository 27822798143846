import { Text, SimpleGrid, Container, Button } from "@mantine/core";
import classes from "./FeaturesWhy.module.css";
import ftwhy from "../../strings/ftrwhyString";
import { Link } from "react-router-dom";

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  image?: string;
  title?: string;
  description?: string;
  button?: string;
}

function Feature({
  image,
  title,
  description,
  button,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />
      <div className={classes.content}>
        {image && (
          // image
          <div>
            <img src={image} alt={title} className={classes.icon} />
          </div>
        )}
        <div>
          {title && <Text className={classes.title}>{title}</Text>}
          {description && (
            <Text className={classes.description}>{description}</Text>
          )}
          {button && (
            <div className={classes.buttonContainer}>
              <Button
                size="sm"
                className={classes.button}
                component={Link}
                to="/contactus"
              >
                {button}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export function FeaturesWhy() {
  const mockdata = ([] = Object.values(ftwhy));
  const items = mockdata.map((item, index) => (
    <Feature {...item} key={index} />
  ));

  return (
    <Container mt={0} mb={0} size="xl" className={classes.featuresContainer}>
      <SimpleGrid cols={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={10}>
        {items}
      </SimpleGrid>
    </Container>
  );
}
