import { Text, SimpleGrid, Container, rem, Button } from "@mantine/core";
import classes from "./ServicesFeaturesWhy.module.css";
import coffee from "../../images/coffee.png";
import { Link } from "react-router-dom"; //

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  image?: string;
  title?: string;
  description?: string;
  button?: string;
}

function ServicesFeatureWhy({
  image,
  title,
  description,
  button,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />
      <div className={classes.content}>
        {image && (
          <div className={classes.left}>
            <img src={image} alt={title} className={classes.icon} />
          </div>
        )}
        <div className={classes.right}>
          {title && <Text className={classes.title}>{title}</Text>}
          {description && (
            <Text className={classes.description}>{description}</Text>
          )}
          {button && (
            <Button
              size="sm"
              className={classes.button}
              component={Link}
              to="/contactus"
            >
              {button}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
export function ServicesFeaturesWhy() {
  const mockdata = [
    {
      image: coffee,
    },
    {
      title: "Why Capital Booster?",
      description:
        "“Capital Booster” is a reliable and prompt credit financing program that instantly fulfils your business needs and enhances your cash flow. It is perfect for companies that are not able to match the credit requirements from financial institutions, or those that require additional working capital on top of their standing facilities. Capital Booster also plays a role in enhancing your chance of obtaining bank loans as it helps to give a face-lift to your account statements and healthier financial condition in your company.",
      button: "Apply Now",
    },
  ];

  const items = mockdata.map((item, index) => (
    <ServicesFeatureWhy {...item} key={index} />
  ));

  return (
    <Container mt={0} mb={0} size="full" className={classes.featuresContainer}>
      <SimpleGrid cols={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={10}>
        {items}
      </SimpleGrid>
    </Container>
  );
}
