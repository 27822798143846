import { Text, SimpleGrid, Container, rem, Title } from "@mantine/core";
import classes from "./ServicesFeatures.module.css";
import featuresData from "../../strings/ServicesFeaturesStrings";

interface FeatureProps extends React.ComponentPropsWithoutRef<"div"> {
  imageUrl: string;
  title: string;
  description: React.ReactNode;
}

function ServicesFeature({
  imageUrl,
  title,
  description,
  className,
  ...others
}: FeatureProps) {
  return (
    <div className={classes.feature} {...others}>
      <div className={classes.overlay} />

      <div className={classes.content}>
        <img
          src={imageUrl}
          alt={title}
          className={classes.icon}
        />
        <Text fz="lg" mb="xs" mt={5} className={classes.featuresTitle}>
          {title}
        </Text>
        <Text fz="sm" mb="xs" className={classes.featuresDescription}>
          {description}
        </Text>
      </div>
    </div>
  );
}

const items = Object.values(featuresData).map((item) => ({
  ...item,
  key: item.title,
}));

export function ServicesFeatures() {
  return (
    <Container mt={0} mb={0} size="full" className={classes.featuresContainer}>
      <Title order={1} className={classes.featuresTitle}>
        Benefits of Financing with Us
      </Title>
      <Text className={classes.featuresDescription}>
        We can help you to achieve your business goal
      </Text>
      <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={0}>
        {items.map((item) => (
          <ServicesFeature {...item} key={item.key} />
        ))}
      </SimpleGrid>
    </Container>
  );
}
