const featuresData = {
  feature1: {
    imageUrl: require('../images/documentation 1.svg').default,
    title: 'Minimal Documentations',
    description:
      'Our Financing requires minimal paperwork, making the application process hassle-free.',
  },
  feature2: {
    imageUrl: require('../images/ranking 1.svg').default,
    title: 'Competitive Rates',
    description: 'We offer competitive interest rates to help maximize your business growth.',
  },
  feature3: {
    imageUrl: require('../images/negotiation 1.svg').default,
    title: 'Flexible Terms',
    description: 'We offer flexible terms to suit your business needs.',
  },
};

export default featuresData;
