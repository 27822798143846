import { useState } from "react";
import {
  Paper,
  Text,
  Title,
  Avatar,
  Badge,
  useMantineTheme,
  rem,
  Button,
} from "@mantine/core";
import classes from "./CustomerStories.module.css";
import Cs from "../../strings/CsString";

interface CardProps {
  stars: number;
  comments: string;
  customerName: string;
  customerPosition: string;
}

function Card({ stars, comments, customerName, customerPosition }: CardProps) {
  const renderStars = (count: number) => {
    const starElements = [];
    for (let i = 0; i < count; i++) {
      starElements.push(
        <Badge variant="transparent" color="orange" size="xl" key={i}>
          ★
        </Badge>
      );
    }
    return starElements;
  };

  return (
    <div className={classes.page}>
      <Paper shadow="md" p="xl" radius="lg" className={classes.card}>
        <div className={classes.stars}>{renderStars(stars)}</div>
        <Text className={classes.comments}>{comments}</Text>
        <div className={classes.avatar}>
          <Avatar
            size="sm"
            radius="lg"
            src="/avatar.png"
            alt="Customer Avatar"
          />
          <div>
            <Title order={4} className={classes.name}>
              {customerName}
            </Title>
            <Text className={classes.position}>{customerPosition}</Text>
          </div>
        </div>
      </Paper>
    </div>
  );
}

// Use the Cs object for data instead of the hardcoded array
const data: CardProps[] = Object.values(Cs);

export function CustomerStories() {
  const theme = useMantineTheme();
  const cardsPerPage = 2;
  const totalCards = data.length;
  const totalPages = Math.ceil(totalCards / cardsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startCardIndex = (currentPage - 1) * cardsPerPage;
  const endCardIndex = Math.min(currentPage * cardsPerPage, totalCards);
  const displayedCards = data
    .slice(startCardIndex, endCardIndex)
    .map((item, index) => (
      <div key={index} className={classes.item}>
        <Card
          stars={item.stars}
          comments={item.comments}
          customerName={item.customerName}
          customerPosition={item.customerPosition}
        />
      </div>
    ));

  const indicators = Array.from({ length: totalPages }, (_, index) => (
    <div
      key={index}
      className={classes.pageIndicators}
      style={{
        width: rem(8),
        height: rem(8),
        borderRadius: "50%",
        backgroundColor:
          currentPage === index + 1
            ? theme.colors.orange[6]
            : theme.colors.gray[4],
        margin: `0 ${rem(4)}`,
      }}
    />
  ));

  return (
    <div className={classes.page}>
      <div className={classes.title}>
        <Title order={2}>Customer Stories</Title>
      </div>
      <div>
        <Text className={classes.text}>
          Discover how SME has strive with Domax
        </Text>
      </div>
      <div className={classes.displayedCards}>{displayedCards}</div>
      <div className={classes.footer}>
        <div className={classes.indicators}>{indicators}</div>
        <div className={classes.arrows}>
          <Button variant="transparent" onClick={goToPrevPage}>
            {/* Placeholder for left arrow */}
            &lt;
          </Button>
          <Button variant="transparent" onClick={goToNextPage}>
            {/* Placeholder for right arrow */}
            &gt;
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomerStories;
