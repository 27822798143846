import { Title, Text, Container, Button, Overlay } from "@mantine/core";
import classes from "./HeroImageBackground.module.css";
import { HeaderTransparent } from "../Static/HeaderTransparent";
import { Link } from "react-router-dom";

export function HeroImageBackground() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <HeaderTransparent />
      </div>

      <Overlay color="#000" opacity={0.85} zIndex={1} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          SME FINANCING
          <Text component="span" inherit className={classes.highlight}></Text>
          <Text className={classes.bydomax}>By Domax</Text>
        </Title>

        <Container size={740}>
          <Text className={classes.description}>
            Secure the funding you need to grow your small or medium-sized
            enterprise with Domax. Our flexible financing options and
            personalized solutions are designed to support your business goals
            and fuel your success.
          </Text>
        </Container>

        <div className={classes.controls}>
          <Button
            className={classes.control}
            variant="white"
            size="lg"
            component={Link}
            to="/contactus"
          >
            Apply Now
          </Button>
        </div>
      </div>
    </div>
  );
}
