import {
    Group,
    UnstyledButton,
    Divider,
    Box,
    Drawer,
    ScrollArea,
    rem,
    useMantineTheme,
    Burger,
  } from '@mantine/core';
  import myLogo from '../../images/domaxlogo.png'
  import { useDisclosure } from '@mantine/hooks';
  import { Link } from 'react-router-dom';
  import {
    IconWorld,
  } from '@tabler/icons-react';
  import classes from './HeaderWhite.module.css';
  export function HeaderWhite() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const theme = useMantineTheme();
  
    return (
      <Box pb={0}>
        <header className={classes.header}>
          <Group justify="space-between" h="100%">
          <Link to ="/" className={classes.link}>
          <img src={myLogo} alt="Domax Logo" className={classes.link} />
          </Link>
  
          <Group h="100%" gap={0} visibleFrom="sm">
            <Link to="/aboutus" className={classes.link}>
              About Us
            </Link>
            <Link to="/services" className={classes.link}>
              Services
            </Link>
            <Link to="/faq" className={classes.link}>
              FAQ
            </Link>
            <Link to="/contactus" className={classes.link}>
              Contact Us
            </Link>
  </Group>
  
            <Group visibleFrom="sm" className={classes.languageSelector}>
          <UnstyledButton className={classes.link}>
            <IconWorld style={{ marginRight: '4px' }} size={20} /> ENG
          </UnstyledButton>
        </Group>
        <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" color='black' />


        </Group>
        </header>
  
        <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
        color='black'
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <Link to="/aboutus" className={classes.drawerlink}>
            About Us
          </Link>
          <Link to="/services" className={classes.drawerlink}>
            Services
          </Link>
          <Link to="/faq" className={classes.drawerlink}>
            FAQ
          </Link>
          <Link to="/contactus" className={classes.drawerlink}>
            Contact Us
          </Link>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}